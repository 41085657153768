.calendar-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px solid;
}
.calendar{
  background: rgba(0, 0, 0, 0);
}