.subtitle{
  text-align: start; 
  margin-left: 1rem;
}

.slides-container{
  width: 100%;
}
.next-btn{
  margin: 1rem;
}
input {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
}
input:focus {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
}
.btn{
margin-top: 1rem;
}
.horizontal-btns{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.avatar{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}