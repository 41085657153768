.active-slots-btns{
  display: flex;
  border: 1px solid white;
}

.active-slots-processes{
  --background: rgba(0,0,0,0);
}
.active-slot-card{
  margin-top: 0px;
  margin-bottom: 1rem;
}